import request from "@/utils/request";

// 销控列表
export const saleControlList = data =>
  request({
    url: `/api/v1/platform/distribution/web/sale/control/list`,
    data,
    method: "post"
  });

// 列表统计
export const controlListCount = data =>
  request({
    url: `/api/v1/platform/distribution/web/sale/control/list/count`,
    data,
    method: "post"
  });

// 销控导入
export const importSaleControl = (data, estateId) =>
  request({
    url: `/api/v1/platform/distribution/web/import/sale/control?estateId=${estateId}`,
    data,
    dataType: "formData",
    method: "post"
  });

// 地块-楼栋-单元联动
export const filterCascade = data =>
  request({
    url: `/api/v1/platform/distribution/web/sale/control/filter/cascade`,
    data,
    method: "post"
  });

// 批量销控
export const saleControlBatch = params =>
  request({
    url: `/api/v1/platform/distribution/web/sale/control/batch`,
    params,
    method: "post"
  });

// 批量删除
export const saleControlBatchDel = params =>
  request({
    url: `/api/v1/platform/distribution/web/sale/control/batch/del`,
    params,
    method: "post"
  });

// 批次列表
export const saleControlFilterBatch = params =>
  request({
    url: `/api/v1/platform/distribution/web/sale/control/filter/batch`,
    params,
    method: "get"
  });

<template>
  <div class="sider">
    <div class="estate-list">
      <div class="title-mol">项目楼盘</div>
      <div class="estate-search">
        <el-input
          placeholder="楼盘名称"
          class="f-search-bar"
          v-model="queryData.keyword"
          @keyup.enter.native="search(1)"
        >
          <el-button slot="append" @click="search(1)">搜索</el-button>
        </el-input>
      </div>
      <ul class="list">
        <li
          v-for="(item, index) in list"
          :key="index"
          :class="{ active: item.estateId == curEstate.estateId }"
          @click="curEstate = item"
        >
          <div class="title">{{ item.name }}</div>
          <p>
            <span>{{ item.propertyTypeStrList.join(" | ") }}</span>
            <span>{{ item.regionName }}</span>
          </p>
        </li>
      </ul>
      <el-pagination
        small
        layout="prev, pager, next"
        :total="total"
        :pager-count="5"
        :hide-on-single-page="true"
        @current-change="search"
        @prev-click="search"
        @next-click="search"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { toThousands } from "@/utils/index";
import { pagedLinkedHouse } from "@/api/estateManage";
export default {
  components: {},
  data() {
    return {
      curEstate: null,
      list: [],
      queryData: {
        keyword: "",
        pageNum: 1,
        pageSize: 11,
        filterIds: "6515"
      },
      total: 0
    };
  },
  created() {
    this.getList();
  },
  watch: {
    "curEstate.estateId": {
      deep: true,
      handler(id) {
        this.$emit("refresh", id);
        this.$emit("getEstateName", this.curEstate.name);
      }
    }
  },
  methods: {
    search(num) {
      // 搜索楼盘
      this.queryData.pageNum = num;
      this.getList();
    },
    async getList() {
      // 获取楼盘列表
      const res = await pagedLinkedHouse(this.queryData);
      const { total, list } = res;
      this.total = total;
      this.list = list;
      this.curEstate = this.list.length ? this.list[0] : {};
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input-group__append {
  padding: 0 10px;
}
.estate-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  ul {
    flex: 1;
    overflow-y: auto;
  }
  .title-mol {
    @include flex-yc();
    height: 40px;
    border-bottom: 1px solid $bdColor;
    font-size: 14px;
    font-weight: bold;
    color: $color3;
    font-weight: bold;
    padding: 0 15px;
  }
  .estate-search {
    padding: 15px;
  }
  li {
    height: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;
    &.active {
      background-color: #eee;
      .title {
        font-weight: bold;
      }
    }
    .title {
      font-size: 16px;
      color: $color3;
      line-height: 100%;
    }
    p {
      font-size: 13px;
      color: $color9;
      line-height: 100%;
      margin-top: 5px;
      span + span {
        margin-left: 8px;
      }
    }
    &:hover {
      .title {
        color: $primary;
      }
    }
  }
}
</style>

<template>
  <div class="house-wrapper">
    <estate-sider class="mr20" @refresh="setEstateId"></estate-sider>
    <div class="content house-list">
      <div class="title-mol">房源销控表</div>
      <div class="filter-box">
        <div class="filter">
          <el-input
            placeholder="房源编号"
            v-model="queryData.number"
            @keyup.enter.native="search"
            style="width: 240px;"
            class="f-search-bar mr10"
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
          <el-select
            v-model="queryData.batch"
            placeholder="批次"
            clearable
            class="mr10"
            @change="search"
          >
            <el-option
              :value="item"
              :label="item"
              v-for="(item, index) in batchList"
              :key="index"
              @change="search"
            ></el-option>
          </el-select>
          <el-cascader
            :options="locationOptions"
            :props="{
              checkStrictly: true,
              value: 'name',
              label: 'name'
            }"
            v-model="location"
            placeholder="位置"
            clearable
            @change="locationChange"
            style="width:140px;"
            class="mr10"
          ></el-cascader>
          <el-select
            v-model="queryData.status"
            placeholder="状态"
            clearable
            class="mr10"
            @change="search"
          >
            <el-option
              :value="item.value"
              :label="item.label"
              v-for="(item, index) in salesStatus"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="action">
          <a
            href="https://fangyibao-res.oss-cn-hangzhou.aliyuncs.com/file/doc/template/%EF%BC%88%E6%A8%A1%E6%9D%BF%EF%BC%89%E6%88%BF%E6%BA%90%E9%94%80%E6%8E%A7%E8%A1%A8.xlsx"
            class="mr10"
          >
            <el-button type="info">下载导入模板</el-button>
          </a>
          <el-upload
            action=""
            :http-request="importHouse"
            :show-file-list="false"
          >
            <el-button type="primary">导入房源</el-button>
          </el-upload>
          <el-button-group class="group-btn">
            <f-table-fields
              name="houseSalesControl"
              :options="defaultFields"
              @change="onFieldsChange"
            >
              <el-button class="ml10" icon="iconfont icon-list"></el-button>
            </f-table-fields>
            <el-button
              icon="iconfont icon-refresh"
              @click="getList"
            ></el-button>
          </el-button-group>
        </div>
      </div>

      <f-table
        ref="multipleTable"
        class="body mt10"
        :data="list"
        :columns="tableColumns"
        show-summary
        border
        :row-class-name="tableRowClassName"
        :summary-method="getSummaries"
        @selection-change="selectionChange"
        @row-click="rowClick"
        :max-height="maxHeight"
      ></f-table>
      <div class="footer-bar mt10">
        <div class="action">
          <el-dropdown
            class="mr10"
            @command="batchSalesControl"
            v-if="houseIds.length"
          >
            <el-button type="info">批量销控</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in salesStatus"
                :key="item.value"
                :command="item.value"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="info" @click="batchDelete" v-if="houseIds.length"
            >批量删除</el-button
          >
        </div>
        <f-pagination
          :page-num.sync="queryData.pageNum"
          :page-size.sync="queryData.pageSize"
          :total="total"
          :fixed="false"
          @change="getList"
        ></f-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { toThousands } from "@/utils/index";
import EstateSider from "../_components/estateSider";
import {
  saleControlList,
  controlListCount,
  importSaleControl,
  filterCascade,
  saleControlBatch,
  saleControlBatchDel,
  saleControlFilterBatch
} from "@/api/saleControl";
export default {
  components: { EstateSider },
  data() {
    return {
      list: [],
      salesStatus: [
        {
          label: "在售",
          value: 2
        },
        {
          label: "认购",
          value: 3
        },
        {
          label: "签约",
          value: 4
        },
        {
          label: "锁定",
          value: 5
        }
      ],
      salesStatusMaps: {
        2: "在售",
        3: "认购",
        4: "签约",
        5: "锁定"
      },
      locationOptions: [], // 位置 地块-楼栋-单元级联数据
      location: "", // 级联选择显示的数据
      batchList: [], // 批次列表
      queryData: {
        estateId: "", // 楼盘Id ,
        number: "", // 房源编号
        batch: null, //批次 ,
        building: null, //楼栋 ,
        massif: null, //地块 ,
        unit: null, //单元
        status: "", //状态: {2: 在售, 3: 认购, 4: 签约, 5: 锁定} = ['2', '3', '4', '5']stringEnum:"2", "3", "4", "5",
        pageSize: 30,
        pageNum: 1
      },
      total: 0,
      maxHeight: 300,
      houseIds: [], // 选中的房源id
      countInfo: {}, // 统计信息
      // 需要展示的字段
      checkedFields: this.$storage.get("houseSalesControlTableFields", []),
      // 默认字段选项
      defaultFields: [
        {
          label: "选择",
          prop: "selectable",
          checked: true,
          disabled: true
        },
        {
          label: "状态",
          prop: "status",
          checked: true,
          disabled: true
        },
        {
          label: "编号",
          prop: "number",
          checked: true,
          disabled: false
        },
        {
          label: "批次",
          prop: "batch",
          checked: true,
          disabled: false
        },
        {
          label: "地块",
          prop: "massif",
          checked: true,
          disabled: false
        },
        {
          label: "楼栋",
          prop: "building",
          checked: true,
          disabled: false
        },
        {
          label: "单元",
          prop: "unit",
          checked: true,
          disabled: false
        },
        {
          label: "楼层",
          prop: "floor",
          checked: true,
          disabled: false
        },
        {
          label: "房号",
          prop: "roomNumber",
          checked: true,
          disabled: false
        },
        {
          label: "建面",
          prop: "buildingSurface",
          checked: true,
          disabled: false
        },
        {
          label: "套内",
          prop: "buildingInsideSurface",
          checked: true,
          disabled: false
        },
        {
          label: "备案总价",
          prop: "recordPrice",
          checked: true,
          disabled: false
        },
        {
          label: "控制收款额",
          prop: "salesReservePrice",
          checked: true,
          disabled: false
        },
        {
          label: "计划收款额",
          prop: "salesPrice",
          checked: true,
          disabled: false
        },
        {
          label: "备注",
          prop: "notes",
          checked: true,
          disabled: false
        }
      ]
    };
  },
  computed: {
    tableColumns() {
      const defaultColumns = [
        {
          label: "选择",
          prop: "selectable",
          type: "selection",
          width: "55px",
          align: "center",
          selectable(row) {
            // 可以选择的行, 关联认购签约订单（不含已作废）的房源，不支持修改状态、批量删除
            return (
              row.status == 2 ||
              row.status == 5 ||
              ((row.status == 3 || row.status == 4) && !row.bindingOrder)
            );
          }
        },
        {
          label: "状态",
          prop: "status",
          render: ({ row }) => <span>{this.salesStatusMaps[row.status]}</span>
        },
        {
          label: "编号",
          prop: "number",
          width: "100px"
        },
        {
          label: "批次",
          prop: "batch",
          placeholder: "-"
        },
        {
          label: "地块",
          prop: "massif",
          placeholder: "-"
        },
        {
          label: "楼栋",
          prop: "building",
          placeholder: "-"
        },
        {
          label: "单元 ",
          prop: "unit",
          checked: true,
          placeholder: "-"
        },
        {
          label: "楼层",
          prop: "floor",
          placeholder: "-"
        },
        {
          label: "房号",
          prop: "roomNumber",
          placeholder: "-"
        },
        {
          label: "建面",
          prop: "buildingSurface",
          placeholder: "-"
        },
        {
          label: "套内",
          prop: "buildingInsideSurface",
          placeholder: "-"
        },
        {
          label: "备案总价",
          prop: "recordPrice",
          placeholder: "-",
          isComma: true,
          width: "140px"
        },
        {
          label: "控制收款额",
          prop: "salesReservePrice",
          placeholder: "-",
          isComma: true,
          width: "140px"
        },
        {
          label: "计划收款额",
          prop: "salesPrice",
          placeholder: "-",
          isComma: true,
          width: "140px"
        },
        {
          label: "备注",
          prop: "notes",
          placeholder: "-"
        }
      ];
      const result = [];
      for (const field of this.checkedFields) {
        const columnIndex = defaultColumns.findIndex(c => c.prop === field);
        if (columnIndex > -1) {
          result.push(defaultColumns[columnIndex]);
          // 储存后就移除 减少下次循环次数
          defaultColumns.splice(columnIndex, 1);
        }
      }
      console.log(result);
      return result;
    }
  },
  created() {
    // 给table一个最大高度，优化显示
    this.maxHeight = document.documentElement.clientHeight - 280;
  },
  methods: {
    onFieldsChange({ checkedFields }) {
      this.checkedFields = checkedFields;
    },
    toThousands,
    search() {
      this.queryData.pageNum = 1;
      this.getList();
    },
    setEstateId(estateId) {
      // 选择楼盘，查询房源
      this.queryData.estateId = estateId;
      this.search();
      this.locationList(); // 地块-楼栋-单元，级联选择，楼盘发生变化时，需重新查询
      this.getBatchList(); // 批次列表
    },
    getList() {
      this.saleControlList(); // 房源列表
      this.controlListCount(); // 房源列表统计
    },
    async saleControlList() {
      // 房源列表
      let params = {
        ...this.queryData
      };
      ["batch", "building", "massif", "unit", "number", "status"].forEach(
        item => {
          if (this.queryData[item] == "") {
            params[item] = null;
          }
        }
      );
      const res = await saleControlList(params);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    },
    async controlListCount() {
      // 房源列表统计
      let params = {
        ...this.queryData
      };
      ["batch", "building", "massif", "unit", "number", "status"].forEach(
        item => {
          if (this.queryData[item] == "") {
            params[item] = null;
          }
        }
      );
      const res = await controlListCount(params);
      if (res) {
        this.countInfo = res;
      }
    },
    async getBatchList() {
      // 获取批次列表
      let params = {
        estateId: this.queryData.estateId
      };
      const res = await saleControlFilterBatch(params);
      if (res) {
        this.batchList = res;
      }
    },
    async locationList() {
      // 位置：地块-楼栋-单元，级联选择
      let params = {
        estateId: this.queryData.estateId
      };
      const res = await filterCascade(params);
      if (res) {
        const replaceEmptyChildren = data => {
          // 将children = []的children置为null
          data.forEach(item => {
            if (item.children instanceof Array && item.children.length > 0) {
              item.children = replaceEmptyChildren(item.children);
            } else {
              item.children = null;
            }
          });
          return data;
        };
        this.locationOptions = replaceEmptyChildren(res);
      }
    },
    locationChange(arr) {
      this.queryData.massif = arr[0] || null;
      this.queryData.building = arr[1] || null;
      this.queryData.unit = arr[2] || null;
      this.search();
    },
    resetFilter() {
      // 重置搜索条件
      this.location = "";
      let arr = ["number", "building", "massif", "unit", "status"];
      arr.forEach(item => {
        this.queryData[item] = null;
      });
    },
    async importHouse(up) {
      // 导入房源
      const res = await importSaleControl(
        { file: up.file },
        this.queryData.estateId
      );
      if (res) {
        this.resetFilter();
        this.search(); // 刷新房源列表
        this.locationList(); // 地块-楼栋-单元，级联选择，楼盘数据发生变化时，需重新查询
        this.getBatchList(); // 批次列表
      }
    },
    async batchSalesControl(status) {
      // 批量销控
      const res = await saleControlBatch({ ids: this.houseIds, status });
      if (res) {
        this.$showSuccess("操作成功");
        this.getList(); // 刷新房源列表
      }
    },
    async batchDelete() {
      // 批量删除
      const res = await saleControlBatchDel({ ids: this.houseIds });
      if (res) {
        this.$showSuccess("删除成功");
        this.getList(); // 刷新房源列表
      }
    },
    checkSelectable(row) {
      // 可以选择的行, 关联认购签约订单（不含已作废）的房源，不支持修改状态、批量删除
      return (
        row.status == 2 ||
        row.status == 5 ||
        ((row.status == 3 || row.status == 4) && !row.bindingOrder)
      );
    },
    tableRowClassName({ row }) {
      // 根据status,给行添加className
      // 2: 在售, 3: 认购, 4: 签约, 5: 锁定
      if (row.status == 3) {
        return "orange";
      }
      if (row.status == 4) {
        return "red";
      }
      if (row.status == 5) {
        return "gray";
      }
      if (this.houseIds.includes(row.id)) {
        return "checked";
      }
    },
    selectionChange(selection) {
      // 选中checkbox，存储房源id
      this.houseIds = selection.map(item => item.id);
    },
    rowClick(row) {
      console.log(row);
      // 点击某一行，触发checkbox选中事件
      if (
        row.status == 2 ||
        row.status == 5 ||
        ((row.status == 3 || row.status == 4) && !row.bindingOrder)
      )
        this.$refs.multipleTable.$refs.table.toggleRowSelection(row);
    },
    getSummaries({ columns }) {
      // 合计
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
        } else {
          sums[index] = this.toThousands(this.countInfo[column.property]);
        }
      });
      return sums;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../_styles/house.scss";
::v-deep .el-table__footer-wrapper tbody td {
  font-weight: bold;
  color: $color3;
}
::v-deep .el-input-group__append {
  padding: 0 10px;
}
::v-deep .el-table td {
  padding: 15px 0;
}
::v-deep .el-button--info.is-plain {
  color: #333;
  &:hover {
    color: #fff;
  }
}
.house-list {
  padding: 0 20px;
  background-color: #fff;
  .title-mol {
    @include flex-yc();
    height: 56px;
    line-height: 100%;
    font-size: 18px;
    font-weight: bold;
    color: $color3;
    &:before {
      display: block;
      content: "";
      width: 4px;
      height: 18px;
      background-color: $primary;
      margin-right: 15px;
      margin-top: 2px;
    }
  }
}
.filter-box {
  @include flex-ycb();
  .el-select {
    width: 125px;
  }
  .action {
    display: flex;
    .group-btn {
      display: flex;
      ::v-deep {
        .el-popover__reference {
          .el-button {
            border-right: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}
</style>

import request from "@/utils/request";
//楼盘管理

/***************************      楼盘管理 -> 推荐楼盘       ***************************/

// 删除推荐楼盘标签
export const deleteTag = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/deleteTag`,
    data
  });

// 添加推荐楼盘
export const editRecommendEstate = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/editRecommendEstate`,
    data
  });

// 编辑推荐楼盘标签
export const editTag = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/editTag`,
    data
  });

// 查询推荐楼盘标签
export const getEstateRecommendTag = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/getEstateRecommendTag`,
    data
  });

// 分页查询推荐楼盘
export const getRecommendEstatePage = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/getRecommendEstatePage`,
    data
  });

// 查询可添加的楼盘列表
export const getUnRecommendEstateList = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/getUnRecommendEstateList`,
    data
  });

// 新增推荐楼盘标签
export const insertTag = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/insertTag`,
    data
  });

// 移除标签下的楼盘
export const removeTagEstate = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/removeTagEstate`,
    data
  });

// 交换楼盘顺序
export const tagEstateSort = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/changeSort`,
    data
  });
/***************************      楼盘管理 ->关联楼盘       ***************************/

// 楼盘列表
export const pagedLinkedHouse = data =>
  request({
    url: `/api/v1/platform/platformEstateMap/pagedLinkedHouse`,
    method: "post",
    data
  });

// 获取操作关联的房源列表
export const linkEstateList = data =>
  request({
    url: `/api/v1/platform/platformEstateMap/linkEstateList`,
    method: "post",
    data
  });

// 关联楼盘
export const linkHouse = data =>
  request({
    url: `/api/v1/platform/platformEstateMap/link`,
    method: "post",
    data
  });

// 删除关联楼盘
export const deleteById = id =>
  request({
    url: `/api/v1/platform/platformEstateMap/delete/${id}`,
    method: "post"
  });

// 推荐开关
export const recommendSwitch = id =>
  request({
    url: `/api/v1/platform/platformEstateMap/recommendSwitch/${id}`,
    method: "post"
  });

// 获取筛选推荐楼盘的数据
export const getRecommendEstateFilterData = data =>
  request({
    url: `/api/v1/platform/web/estate/recommend/filter`,
    data
  });

// 获取平台关联楼盘
export const getPlatformEstateList = data =>
  request({
    url: `/api/v1/platform/web/article/getPlatformEstateList`,
    data
  });

// 分销楼盘排序
export const platformEstateMapSort = (id, data) =>
  request({
    url: `/api/v1/platform/platformEstateMap/sort/${id}`,
    method: "post",
    dataType: "formData",
    data
  });
